/* eslint-disable import/no-unresolved */
<template>
  <section id="live-speakers">
    <p>Gerenciamento de palestrante ao vivo: {{ $route.query.live.split('/')[1] }}</p>
    <div>
      <add-new
        :is-add-new-sidebar-active.sync="isAddNewSpeakerSidebarActive"
        @refetch-data="refetchData"
        @close="isAddNewSpeakerSidebarActive = false"
        @refresh="loadTableData()"
      />

      <edit-speaker
        :is-edit-speaker-sidebar-active.sync="isEditSpeakerSidebarActive"
        :data="rowData"
        @refetch-data="refetchData"
        @close="isEditSpeakerSidebarActive = false"
        @refresh="loadTableData()"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>palestrantes</label>
            </b-col>

            <!-- <b-col
              cols="12"
              md="4"
            >
              <b-form-select
                id="list"
                v-model="selectValue"
                :options="events"
                class="w-75"
              >
                <template #first>
                  <option value="">
                    Selecione o evento
                  </option>
                </template>
              </b-form-select>
            </b-col> -->
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchValue"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
                />
                <b-button
                  variant="primary"
                  @click="isAddNewSpeakerSidebarActive = true"
                >
                  <span class="text-nowrap">Adicionar palestrante</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="overflow-auto">
          <b-table
            id="my-table"
            ref="refUserListTable"
            class="position-relative"
            :items="filter"
            :fields="tableColumnsSpeakers"
            :current-page="currentPage"
            :per-page="perPage"
            responsive
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >
            <!-- Column: Role -->
            <template #cell(role)="data">
              <div class="text-nowrap">
                <feather-icon
                  :icon="resolveUserRoleIcon(data.item.role)"
                  size="18"
                  class="mr-50"
                  :class="`text-${resolveUserRoleVariant(data.item.role)}`"
                />
                <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ data.item.status }}
              </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="edit(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteSpeakerEvent(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">A partir de {{ dataMeta.from }} para {{ dataMeta.to }} de {{ dataMeta.of }} palestrantes</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useList from './useList'
import AddNew from './AddNew.vue'
import EditSpeaker from './Edit.vue'
import { mapActions } from 'vuex'
import LivesServices from '../LivesServices'

export default {

  components: {
    AddNew,
    EditSpeaker,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const {
      fetchUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {
      fetchUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalSpeakerEvent: 0,
      events: [],
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValue: '',
      filter: [],
      isAddNewSpeakerSidebarActive: false,
      isEditSpeakerSidebarActive: false,
      searchValue: null,
      rowData: {
        id: null,
        live_id: null,
        speaker_id: null,
        password_vmix: null
      },
      tableColumnsSpeakers: [
        {
          key: 'live.title',
          label: 'Atividade',
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
        {
          key: 'password_vmix',
          label: 'Chave VMIX',
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
        {
          key: 'speaker.name_badge',
          label: 'Palestrante',
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
        {
          key: 'actions',
          label: 'Ações',
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
      ],
    }
  },
  computed: {
    rows() {
      return this.filter.length
    },
  },
  watch: {
    searchValue() {
      // add an if statement to regulate arrays if null/'' show all results
      this.filter = []
      this.filter = this.speakerEvent.filter(this.search())
    },
    selectValue() {
      console.log(this.selectValue)
      this.filter = []
      this.filter = this.speakerEvent.filter((item1) => item1.event.id === this.selectValue)
    },
  },
  mounted() {
    this.loadTableData()
  },
  methods: {
    ...mapActions('congressAdministrator', [
      'ActionListAllSpeakerEvent',
      'ActionDeleteSpeakerToEvent'
    ]),
    search() {
      return (item1) => this.searchValue.toLowerCase().split(' ').every(v => item1.speaker.name_badge.toLowerCase().includes(v))
      // return (item) => this.searchValue.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v))
    },
    edit(data) {
      this.rowData.id = data.id
      this.rowData.speaker_id = data.speaker.id
      this.rowData.live_id = data.live.id
      this.rowData.password_vmix = data.password_vmix
      this.isEditSpeakerSidebarActive = true
    },
    deleteSpeakerEvent(data) {
      this.$swal({
        title: 'Deletar palestrante do evento?',
        text: 'Você não será capaz de reverte essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, desejo deletar!',
        showLoaderOnConfirm: true,
        preConfirm: () => LivesServices().speakerVmixDelete({
          id: data.id
        }).then(() => {
          this.loadTableData()
        }).catch(err => {
          this.$swal.fire(
            'Opss!',
            'Erro ao tentar deletar.',
            'error'
          )
        })
          .finally(() => {
            this.isBusy = false
          }),
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Deletado!',
            'Congresso deletado com sucesso.',
            'success'
          )
        }
      })
    },
    loadTableData() {
      LivesServices().speakerVmixList({
        slug: this.$route.params.slug
      }).then(api => {
        this.filter = api.data.response.liveSpeaker
      })
    }
  },
}
</script>

<style lang="scss">
</style>
