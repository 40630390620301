// eslint-disable-next-line import/no-unresolved
import axios from '@axios'

export default function LivesServices() {
  const getLives = async (queryParams) => {
    const api = await axios.get('/lives', queryParams)
    return api
  }

  const getLiveFrame = async (queryParams) => {
    const api = await axios.get('/lives/frame/id', {
      params: queryParams,
    })
    return api
  }

  const getLiveSpeakerBySpeaker = async (queryParams) => {
    const api = await axios.get('/live/speaker/byspeaker', {
      params: queryParams,
    })
    return api
  }

  const getLiveSpeakerByLive = async (queryParams) => {
    const api = await axios.get('/live/speaker/bylive', {
      params: queryParams,
    })

    return api
  }

  const liveMonitoring = async (queryParams) => {
    const api = await axios.post('/live/monitoring', queryParams)
    return api
  }

  const liveCreate = async (queryParams) => {
    const api = await axios.post('/live/create', queryParams)
    return api
  }

  const liveDelete = async (queryParams) => {
    const api = await axios.post('/live/delete', queryParams)
    return api
  }

  const liveEdit = async (queryParams) => {
    const api = await axios.post('/live/update', queryParams)
    return api
  }

  // requests for vmix
  const speakerVmixCreate = async (queryParams) => {
    const api = await axios.post('/live/speaker/create', queryParams)
    return api
  }

  const speakerVmixUpdate = async (queryParams) => {
    const api = await axios.post('/live/speaker/update', queryParams)
    return api
  }

  const speakerVmixDelete = async (queryParams) => {
    const api = await axios.post('/live/speaker/delete', queryParams)
    return api
  }

  const speakerVmixList = async (queryParams) => {
    const api = await axios.post('/live/speaker', queryParams)
    console.log(api)
    return api
  }

  return {
    getLives,
    getLiveFrame,
    getLiveSpeakerBySpeaker,
    getLiveSpeakerByLive,
    liveMonitoring,
    liveCreate,
    liveDelete,
    liveEdit,
    speakerVmixCreate,
    speakerVmixUpdate,
    speakerVmixDelete,
    speakerVmixList,
  }
}
