<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isEditSpeakerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
  >
    <template #default="{}">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Editar palestrante
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="$emit('close')"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <!-- Event -->
          <!-- <validation-provider
            v-slot="{ errors }"
            name="Event"
            rules="required"
          >
            <b-form-group
              label="Evento"
              label-for="event"
            >
              <v-select
                id="event"
                v-model="event_id"
                :options="events"
                label="text"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider> -->

          <!-- Speaker -->
          <validation-provider
            v-slot="{ errors }"
            name="Speaker"
            rules="required"
          >
            <b-form-group
              label="Palestrante"
              label-for="speaker"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="speaker"
                v-model="speaker_id"
                :options="speakers"
                label="text"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Chave vmix -->
          <validation-provider
            v-slot="{ errors }"
            name="keyVmix"
          >
            <b-form-group
              label="Chave VMIX"
              label-for="keyVmix"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="keyVmix"
                v-model="form.password_vmix"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$emit('close')"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import countries from '@/@fake-db/data/other/countries'
import { mapActions } from 'vuex'
import { integer } from 'vee-validate/dist/rules'
import LivesServices from '../LivesServices'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isEditSpeakerSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      speaker_id: null,
      live_id: null,
      password_vmix: null,
      events: [],
      speakers: [],
      form: {
        id: null,
        live_id: null,
        speaker_id: null,
        password_vmix: null
      }
    }
  },
  computed: {
    speakersState() {
      return this.$store.state.congressAdministrator.speakers
    }
  },
  watch: {
    isEditSpeakerSidebarActive() {
      if (this.isEditSpeakerSidebarActive) {
        this.speakers = []
        this.speakersState.forEach(element => {
          this.speakers.push({
            value: element[0].id,
            text: element[0].name_badge
          })
          if (this.data.speaker_id === element[0].id) {
            this.speaker_id = {
              value: element[0].id,
              text: element[0].name_badge
            }
          }
          this.speakers.sort(this.sortByName)
        })

        this.form.id = this.data.id
        this.form.live_id = this.data.live_id
        this.form.speaker_id = this.data.speaker_id
        this.form.password_vmix = this.data.password_vmix
      }
    },
  },
  async mounted() {
    await this.ActionListAllSpeakers()
  },
  methods: {
    resetForm() {
      this.form = {
        id: null,
        live_id: null,
        speaker_id: null,
        password_vmix: null
      }
    },
    ...mapActions('congressAdministrator', [
      'ActionEditSpeakerToEvent',
      'ActionListAllEvents',
      'ActionListAllSpeakers'
    ]),
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            const response = await LivesServices().speakerVmixUpdate({
              id: this.data.id,
              live_id: this.form.live_id,
              speaker_id: this.form.speaker_id,
              password_vmix: this.form.password_vmix
            })

            if (response.data.status === 400) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: response.data.message,
                  text: ` ERROR: ${response.data.errors[0][0]} #${response.data.errors[0][1]}`,
                  variant: 'danger',
                }
              })
            } else if (response.data.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'InfoIcon',
                  variant: 'success',
                }
              })
              this.$emit('close')
              this.$emit('refresh')
            }
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
